.answers-container {
  padding-top: 4em;
}

.answer-bg {
  position: relative;
  height: 100vh;
  background-image: linear-gradient(to top, #2575fc 0%, #6a11cb 100%);
}

.answers-txt-box {
  border-radius: 20px !important;
  border: 5px #2575fc solid !important;
  padding: 25px 10px !important;
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: center;
}
.answers-form-text {
  text-align: center;
  font-style: italic;
  color: #fff !important;
}

/* Option */
.option-btn {
  margin: 1em 0;
  cursor: pointer;
  display: block;
  border: 0.4em solid #fff;
  border-radius: 1em;
  padding: 0.5em 0;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.4);
  transition-duration: 0.6s;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.select {
  background-color: #fff;
  color: #6a11cb;
}

.active {
  background-color: #fff;
  color: #6a11cb;
}

.action-button {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  width: 100%;
  padding: 1em 2em;
  background-color: #232526;
  border: none;
  border-radius: 1em;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  font-weight: 600;
  transition-duration: 0.4s;
  align-self: center;
  align-content: center;
  margin: 0 auto;
  display: block;
  box-shadow: 2px 0px 28px -3px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 2px 0px 28px -3px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 2px 0px 28px -3px rgba(0, 0, 0, 0.46);
}

.txt-center {
  text-align: center !important;
}

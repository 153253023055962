.question-bg {
  padding: 0 !important;
  background-image: linear-gradient(to top, #6a11cb 0%, #2575fc 100%);
  height: 100vh;
  text-align: center;
}
.single-question {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  width: 70%;
  margin: auto;
  font-size: 3em;
  line-height: 1.2em;
  text-align: center;
  font-weight: 600;
  text-shadow: -1px 1px 7px rgba(0, 0, 0, 0.05);
}

.timer {
  border-radius: 0 !important;
  height: 1.5rem !important;
  background-color: #fff !important;
}

.question-number {
  margin: 2em 0;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2em;
  font-weight: bold;
  font-style: oblique;
  color: rgba(255, 255, 255, 0.5);
}

.answer {
  margin: 1.8em 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  font-size: 3em;
  line-height: 1.2em;
  text-align: center;
  font-weight: 600;
  text-shadow: -1px 1px 7px rgba(0, 0, 0, 0.05);
}

.timer .progress-bar {
  background-color: #2575fc;
}

.q-action-button {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  padding: 1em 2em;
  background-color: transparent;
  border: 0.5em solid #fff;
  border-radius: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  transition-duration: 0.4s;
  align-self: center;
}

.action-button:hover {
  background-color: #2575fc;
}
